import "@/css/jagatv.css";
import React, { useState, useEffect } from "react";
import Layout from "@/components/layout";
import Footer from "@/components/footer";
import HeaderOneTop from "@/components/header-one-top";
import HeaderOne from "@/components/header-one";
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";
import Content from "@/components/jagatv/content";
import api from "@/axios";
import { navigate } from "gatsby";

const JagaTv = ({ params }) => {
  const [apiData, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await api.get(`/movies/get/${params.id}`);
        if (
          response.data &&
          response.data.status &&
          response.data.status == "error"
        ) {
          navigate("/jagatv/", { replace: true });
        }
        setData(response.data);
        setError(null);
      } catch (err) {
        setError(err.message);
        navigate("/jagatv/", { replace: true });
        setData(null);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, []);

  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Jaga TV">
          <HeaderOneTop />
          <HeaderOne />
          {!loading && apiData && <Content data={apiData} />}
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default JagaTv;
