import "@/css/jagatv.css";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, navigate } from "gatsby";
import ReactPlayer from "react-player/lazy";

import arrowBack from "@/images/slider/arrow-previous.svg";

const TopInfo = ({ data }) => {
  const { name, processedName, categories, tags, url } = data;
  return (
    <section className="commonSection jagatv-section">
      <Container>
        <Row className="mb-4 align-items-center justify-content-center">
          <h5 className="text-uppercase text-start fw-bold me-auto">
            <img
              src={arrowBack}
              className="me-4 mb-1"
              role="button"
              onClick={() => navigate(-1)}
            />
            JAGA TV {">"} {name}
          </h5>
        </Row>
        <Row className="player-wrapper justify-content-center">
          <ReactPlayer
            className="react-player"
            url={url}
            width="89%"
            height="100%"
            controls="true"
            // playing={true}
            // muted={true}
          />
        </Row>
      </Container>
    </section>
  );
};

export default TopInfo;
